// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-wapps-redirect-js": () => import("/site/.cache/@wapps/redirect.js" /* webpackChunkName: "component---cache-wapps-redirect-js" */),
  "component---src-pages-index-js": () => import("/site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/site/.cache/data.json")

